<template>
  <div id="app" class="flex justify-center items-center h-screen">
    <div id="iframe-container" class="absolute top-0 left-0 w-full h-full">
      <iframe id="my-iframe" :src="iframeSrc" class="w-full h-full border-none"></iframe>
    </div>
    <div id="overlay" v-show="show" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"></div>
    <div id="popup" v-show="show" class="popup-container relative rounded-2xl text-center">
      <div class="custom-bg"></div>

      <MissingDeposit 
        v-if="currentScreen === 6"
        :todayTotal="todayTotal"
        :iframeSrc="iframeSrc"
        @notifications-disabled="handleNotificationsDisabled" />

      <PushNotification
        v-if="(isPWA || isDireto) && currentScreen === 0 && !notificationsGranted"
        @notifications-enabled="handleNotificationsEnabled"
        @notifications-disabled="handleNotificationsDisabled"
      />

      <ScreenOne 
        v-if="currentScreen === 1" 
        :database="database"
        :referral="referral"
        @update-today-total="updateTodayTotal"
        @missing-deposit="showMissingDeposit"
        @next-screen="showScreen2" />

      <ScreenTwo 
        v-if="currentScreen === 2" 
        @next-screen="showScreen3" />

      <ScreenThree
        v-if="currentScreen === 3"
        :selectedGame="selectedGame"
        :checked1="checked1"
        :checked2="checked2"
        :checked3="checked3"
        :isActive="isActive"
        @previous-screen="showScreen2"
        @next-screen="showScreen4"
        @update-checked="updateChecked" />

      <ScreenFour 
        v-if="currentScreen === 4" 
        :selectedGame="selectedGame" 
        @modification-complete="handleModificationComplete" />

      <AddToHomeScreen
        v-if="currentScreen === 5" :deferredPrompt="deferredPrompt" />
    </div>

    <FloatingNotification
      v-if="showFloatingNotification"
      :selectedGame="selectedGame"
      :isActive="isActive"
      @notification-click="handleNotificationClick"
      @update-checked="updateChecked" />

    <ScreenSettings
      v-if="showSettings && selectedGame"
      :selectedGame="selectedGame"
      :checked1="checked1"
      :checked2="checked2"
      :checked3="checked3"
      :isActive="isActive"
      @close-settings="closeSettings"
      @expand-game="expandGame"
      @save-settings="saveSettings"
      @update-checked="updateChecked" />
  </div>
</template>

<script>
import ScreenOne from './components/ScreenOne.vue';
import ScreenTwo from './components/ScreenTwo.vue';
import ScreenThree from './components/ScreenThree.vue';
import ScreenFour from './components/ScreenFour.vue';
import FloatingNotification from './components/FloatingNotification.vue';
import ScreenSettings from './components/ScreenSettings.vue';
import AddToHomeScreen from './components/AddToHomeScreen.vue';
import PushNotification from './components/PushNotification.vue';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ref, set, get } from "firebase/database";
import { database, messaging, getToken } from './firebaseConfig.js';
import MissingDeposit from './components/MissingDeposit.vue';
import api from './services/api';

export default {
  name: 'App',
  components: {
    ScreenOne,
    ScreenTwo,
    ScreenThree,
    ScreenFour,
    FloatingNotification,
    ScreenSettings,
    AddToHomeScreen,
    PushNotification,
    MissingDeposit,
  },
  data() {
    return {
      show: false,
      currentScreen: 0, // Inicia em 0 para mostrar PushNotification se estiver em PWA
      selectedGame: null,
      showFloatingNotification: false,
      iframeSrc: 'https://hypezbet.com/',
      showSettings: false,
      checked1: false,
      checked2: false,
      checked3: false,
      isActive: true,
      deferredPrompt: null,
      isPWA: window.matchMedia('(display-mode: standalone)').matches,
      isDireto: (new URL(window.location.href)).pathname.includes('/dr'),
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      notificationsGranted: false,
      visitorId: 'foo',
      todayTotal: 0,
      referral: '',
    };
  },
  methods: {
    updateTodayTotal(value) {
      this.todayTotal = value;
    },
    closePopup() {
      this.show = false;
    },
    showScreen2() {
      this.currentScreen = 2;
      this.iframeSrc = 'https://hypezbet.com/';
    },
    showScreen3(selectedGame) {
      this.selectedGame = selectedGame;
      this.iframeSrc = selectedGame.iframe_src;
      this.currentScreen = 3;
    },
    expandGame(selectedGame) {
      this.showScreen3(selectedGame);
      this.show = true;
      this.showFloatingNotification = false;
      this.showSettings = false;
    },
    showScreen4() {
      this.currentScreen = 4;
    },
    handleModificationComplete() {
      this.showFloatingNotification = true;
      this.show = false;
      document.getElementById('iframe-container').classList.remove('blur');
    },
    handleNotificationClick() {
      this.showFloatingNotification = false;
      this.showSettings = 3;
      this.show = false;
      document.getElementById('iframe-container').classList.add('blur');
    },
    closeSettings() {
      this.showSettings = false;
      this.showFloatingNotification = true;
      document.getElementById('iframe-container').classList.remove('blur');
    },
    saveSettings() {
      this.showSettings = false;
      this.showFloatingNotification = true;
      document.getElementById('iframe-container').classList.remove('blur');
    },
    updateChecked(values) {
      this.checked1 = values.checked1;
      this.checked2 = values.checked2;
      this.checked3 = values.checked3;
      this.isActive = values.isActive;
    },
    updateFCMToken() {
      getToken(messaging, { vapidKey: 'BBXU8VejhyacmsVb_eUe-iN9r-2LbBP5quvO8zH2kcJ9828VYsMGLVczvJgeEvW0LiZ3yV0Kleh9TpEG3hUpSrk' })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Token retrieved: ', currentToken);
          // api 
          FingerprintJS.load().then(fp => {
            fp.get().then(result => {
              this.visitorId = result.visitorId;
              api.post('notification/api/fcm-token/', {
                token: currentToken,
                device_id: this.visitorId
              }).then((response) => {
                  console.log('Token saved to database', response);
              }).catch((error) => {
                console.error('Error saving token to database:', error);
              });
            });
          });
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    },
    getQueryStringFromDb(visitorId){
      // const visitorId = this.visitorId;
      const queryStringRef = ref(database, 'visitorId/' + visitorId + '/queryString');
      get(queryStringRef).then((snapshot) => {
        const queryString = snapshot.val();
        if (queryString) {
          this.iframeSrc += queryString;
        }
      });
    },
    checkPWAStatus() {
      // const url = ;
      console.log('this.isDireto', this.isDireto)
      if (this.isPWA) {
        console.log('Running as PWA');
        // force to get token on PWA
        this.updateFCMToken();
        // Recupera a query string do Realtime Database
        this.getQueryStringFromDb(this.visitorId);

        this.currentScreen = 0;
      } else {
        console.log('Not running as PWA');
        if(!this.isDireto){
          this.showAddToHomeScreen();
        }else{
          console.log('Running as Direto');
          if(this.isIOS){
            console.log('Running as IOS');
            this.currentScreen = 1;
          }else{
            if (this.isChrome) {
              console.log('Running as Chrome');
              this.currentScreen = 0;
            } else {
              console.log('Not running as Chrome');
              this.currentScreen = 1;
            }
          }
        }
      }
    },
    checkNotificationPermission() {
      try {
        if (Notification.permission === 'granted') {
          this.notificationsGranted = true;
          this.currentScreen = 1; // Vai direto para ScreenOne se a permissão já foi concedida
        } else {
          this.notificationsGranted = false;
        }
      } catch (e) {
        console.log('Error checking notification permission:', e);
      }
    },
    showAddToHomeScreen() {
      this.currentScreen = 5;
      this.show = true;
      document.getElementById('iframe-container').classList.add('blur');
    },
    handleNotificationsEnabled() {
      this.notificationsGranted = true;
      this.currentScreen = 1; // Avança para ScreenOne
    },
    handleNotificationsDisabled() {
      this.currentScreen = 1; // Avança para ScreenOne
    },
    showMissingDeposit() {
      this.currentScreen = 6;
      this.show = true;
      document.getElementById('iframe-container').classList.add('blur');
    },
    setReferral(queryString) {
      // get ref= from the URL else none
      this.referral = new URLSearchParams(queryString).get('c');
    },
    saveQueryStringToDatabase(queryString) {
      FingerprintJS.load().then(fp => {
        fp.get().then(result => {
          this.visitorId = result.visitorId;
          // this.iframeSrc += queryString;
          console.log(result.visitorId);

          set(ref(database, 'visitorId/' + result.visitorId + '/queryString'), queryString)
            .then(() => {
              console.log('Query string saved to database');
            })
            .catch((error) => {
              console.error('Error saving query string to database:', error);
            });
        });
      });
      
    },
  },
  mounted() {

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });

    // if PWA or not we need to get the query string from the database
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        this.visitorId = result.visitorId;
        console.log(result.visitorId, 11111111);

        const queryStringRef = ref(database, 'visitorId/' + this.visitorId + '/queryString');
        get(queryStringRef).then((snapshot) => {
          const queryString = snapshot.val();
          if (queryString) {
            this.setReferral(queryString);
            this.saveQueryStringToDatabase(queryString);
            this.iframeSrc += queryString;
          }
        });
      });
    });

    // when is not PWA we need to check if there is a query string in the URL and save it to the database
    const queryString = window.location.search;
    if (queryString) {
      this.setReferral(queryString);
      this.saveQueryStringToDatabase(queryString);
    }


    setTimeout(() => {
      this.show = true;
      document.getElementById('iframe-container').classList.add('blur');
    }, 2000);

    this.checkPWAStatus();
    this.checkNotificationPermission();

  }
};
</script>

<style>
html, body {
  background-color: #0f1923;
}
#app {
  display: flex;
  justify-content: center;
  align-items: center;
}
#iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.custom-bg {
  background-color: #130A1E6E;
  border: 1px solid #5D477970;
  border-radius: 10px;
  opacity: 0.94;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.close-button {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  background: none;
  border: none;
  color: #4e4879;
  font-size: 1.5rem;
  cursor: pointer;
}
.popup-container {
  max-width: 400px;
  width: 95%;
  z-index: 10;
  position: relative;
}
</style>
